import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import { SocialIcon } from 'react-social-icons'

export default function Profile() {
  return (
    <section>
      <br />
      <Grid container spacing={0} justifyContent="flex-end" sx={{ width: '100%', height: '100%' }} alignItems="center">
        <Grid item xs={12}>
          <center>

            <Grid container spacing={1} justifyContent="flex-end" alignItems="center"
              sx={{
                boxShadow: 2,
                border: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#00000055',
                color: 'white',
              }}
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={4} justifyContent="flex-start" alignItems="center">
                <Avatar
                  alt="Jirapong Wattanatom"
                  src="/images/jay.jpeg"
                  sx={{ width: 128, height: 128 }}
                />
              </Grid>
              <Grid item xs={4} justifyContent="flex-start" alignItems="center">
                <h1>Hi ! I'm Jirapong Wattanatom</h1>
                <h5>Current position: <br/>	&#8226; Devops - Site Reliability Engineer@Agoda <br/> &#8226; Son<br/>	&#8226; Brother<br/> &#8226; Uncle<br/>	&#8226; Dad of 11 Sugar gliders</h5>
                <h5>Improve uptime, Incident management<br /> Alerts & Monitoring, Anomaly Detection</h5>
                <h5>
                  <SocialIcon url='https://www.linkedin.com/in/jirapong-wattanatom-b789b8a0/' style={{ width: '40px', height: '40px' }} fgColor='white' bgColor='black' />&nbsp;
                  <SocialIcon url='https://github.com/jayhyuk' style={{ width: '40px', height: '40px' }} fgColor='white' bgColor='black' />&nbsp;
                  <SocialIcon url='https://www.instagram.com/jayheij/' style={{ width: '40px', height: '40px' }} fgColor='white' bgColor='black' />&nbsp;
                </h5>
                <h5>Email: jay.jiraponw@gmail.com</h5>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </center>
        </Grid>
      </Grid>
      <br />
    </section>
  );
}
